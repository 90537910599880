<template>
  <div class="orders">
    <div class="status-bar">
      <h2>Group orders</h2>
      <div class="orders__date-filters">
        <group-orders-date-filters v-model="selectedMonth" />
      </div>
    </div>
    <div class="orders__value-filters"></div>
    <div class="orders__table" id="groups-orders-table">
      <group-orders :month="selectedMonth" @loading="loadingView" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import GroupOrders from "../components/orders/groups/groupOrders.vue";
import GroupOrdersDateFilters from "../components/orders/groups/groupOrdersDateFilters.vue";

export default {
  name: "Orders",
  title: "SE-Group-Orders",
  data() {
    return {
      loading: false,
      selectedMonth: "",
    };
  },
  computed: {
    ...mapState({
      yearMonths: (state) => state.years.yearMonths,
      currentYear: (state) => state.years.currentSchoolYear,
    }),
  },
  components: { GroupOrdersDateFilters, GroupOrders },
  methods: {
    getOrders: async function () {
      this.loadingView(true);
      let month, year;
      [month, year] = [...JSON.parse(this.selectedMonth)];
      let schoolyear = this.currentYear.id;

      await this.$store
        .dispatch("orders/getOrdersGroupsAction", {
          month,
          year,
          schoolyear,
        })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.$vs.notify({
            title: "Ошибка получения данных",
            text: "",
            color: "danger",
            position: "top-right",
          });
        });
      this.loadingView(false);
    },
    loadingView: function (view) {
      let el = document.querySelector("#groups-orders-table");
      if (!el) return;
      if (view) {
        this.$vs.loading({
          container: "#groups-orders-table",
        });
      }
      this.$vs.loading.close("#groups-orders-table > .con-vs-loading");
    },
  },
  watch: {
    selectedMonth: function () {
      this.getOrders();
    },
  },
};
</script>
<style lang="scss">
.orders {
  .status-bar {
    .orders__date-filters {
      margin: 0 1rem;
      .con-select {
        margin-top: 0;
      }
    }
  }
}
.orders__list {
  margin: 0 15px;
}
#groups-orders-table {
  position: relative;
  .con-vs-loading {
    .vs-loading {
      margin: 25% auto !important;
    }
  }
}
</style>
