<template>
  <div>
    <vs-icon class="close" icon="close" @click="$emit('closeForm')"></vs-icon>
    <form
      @submit="saveForm($event)"
      class="edit-order-form"
      @keyup.enter="saveForm($event)"
    >
      <div class="">
        <vs-avatar icon="people" :color="order.group.color" />
        Group: {{ order.group.name }}
      </div>

      <div class="field">
        <vs-input
          :label="`Correct in ${curMonth.format('MMMM')}`"
          v-model="lesson_correct"
          ref="input"
        ></vs-input>
      </div>
      <div class="field">
        <vs-textarea
          :label="`Correct note`"
          v-model="correct_note"
          counter="250"
          :placeholder="order.correct_note"
        ></vs-textarea>
      </div>

      <div class="title">
        Lessons to pay: {{ lesson_cur_pay || this.order.lesson_cur_pay }}
      </div>
      <div class="title">To pay: {{ value || this.order.value }}</div>
      <vs-button icon="save" @click="saveForm($event)">Save</vs-button>
    </form>
  </div>
</template>

<script>
export default {
  props: ["order", "prevMonth", "curMonth"],
  data() {
    return {
      lesson_correct: this.order ? this.order.lesson_correct : 0,
      correct_note: this.order ? this.order.correct_note : "",
    };
  },
  computed: {
    value: function () {
      const bouns = this.order.evening ? 100 : 0;
      return this.lesson_cur_pay * this.order.price + bouns;
    },
    lesson_cur_pay: function () {
      return (
        parseInt(this.order.lesson_cur_pay_original, 10) +
        parseInt(this.lesson_correct, 10)
      );
    },
  },
  methods: {
    saveForm: function (e) {
      e.preventDefault();
      const user = JSON.parse(localStorage.getItem("user"));
      const correct_user = { id: user.id, nickname: user.name };
      const { lesson_correct, value, lesson_cur_pay, correct_note } = this;
      this.$emit("saveOrder", {
        order: this.order,
        lesson_correct,
        lesson_cur_pay,
        value,
        correct_note,
        correct_user,
      });
    },
    focus: function () {
      setTimeout(() => {
        this.$refs.input.$refs.vsinput.select();
      }, 500);
    },
  },
  created: function () {
    this.focus();
  },
  // updated: function () {
  //   this.focus();
  // },
};
</script>

<style lang="scss" scope>
.close {
  position: absolute;
  top: 30px;
  right: 10px;
  cursor: pointer;
}
.edit-order-form > div {
  margin: 10px 0;
}
</style>