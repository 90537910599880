<template>
  <div class="orders__filters-date">
    <vs-select v-model="selectedMonth" @change="handleChange">
      <vs-select-item
        :key="index"
        :value="month.value"
        :text="`${month.name}`"
        v-for="(month, index) in months"
      />
    </vs-select>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  prop: ["value"],
  data() {
    return {
      selectedMonth: "",
    };
  },
  computed: {
    ...mapState({
      currentYear: (state) => state.years.currentSchoolYear,
    }),
    months: function () {
      if (!this.currentYear.id) return [];

      let months = [];
      const now = new Date();
      let startDate = this.$moment.unix(this.currentYear.date_start_unix);
      let endDate = this.$moment.unix(this.currentYear.date_end_unix);
      while (startDate.isBefore(endDate)) {
        months.push({
          name:
            startDate.format("MMMM") +
            (startDate.year() != now.getFullYear()
              ? ` ${startDate.year()}`
              : ""),
          value: JSON.stringify([
            parseInt(startDate.format("M"), 10),
            parseInt(startDate.format("YYYY"), 10),
          ]),
        });
        startDate.add(1, "month");
      }
      return months;
    },
  },
  methods: {
    handleChange: function () {
      this.$emit("input", this.selectedMonth);
    },
  },
  created() {
    const now = new Date();
    this.selectedMonth = JSON.stringify([
      now.getMonth() + 1,
      now.getFullYear(),
    ]);
    this.handleChange();
  },
};
</script>

<style>
</style>