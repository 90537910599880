<template>
  <div class="report-table">
    <div class="report-table__wrapper">
      <vs-progress
        :percent="(savedOrdersCount * 100) / toSaveOrdersCount"
        color="primary"
        v-if="toSaveOrdersCount"
        >primary</vs-progress
      >
      <vs-table
        class="orders-table"
        :data="filteredOrders"
        hoverFlat
        multiple
        v-model="selectedOrders"
      >
        <template slot="header">
          <h3>Orders by {{ curMonth.format('MMMM') }}</h3>
          <div class="order__manage">
            <vs-button
              icon="vertical_align_bottom"
              type="flat"
              color="dark"
              class="small"
              @click="exportTable"
            >
              Export to .xls
            </vs-button>
            <vs-button
              icon="refresh"
              type="flat"
              color="dark"
              class="small"
              @click="recalcStudentsInGroupOrders"
              v-if="selectedOrders.length"
              title="Reaclculate student orders in selected groups"
            >
              Recalculate students
            </vs-button>
            <vs-button
              icon="rotate_right"
              type="flat"
              color="primary"
              class="small"
              @click="reloadGroupOrders"
              title="Reaclculate student orders in selected groups"
            >
              Reload groups orders
              {{
                selectedOrders.length
                  ? `(${selectedOrders.length} selected)`
                  : ''
              }}
            </vs-button>

            <vs-button icon="save" @click="saveOrders" class="small"
              >Save</vs-button
            >
          </div>
        </template>
        <template slot="thead">
          <th></th>
          <vs-th sort-key="name">Group</vs-th>
          <vs-th sort-key="days">Days</vs-th>
          <vs-th sort-key="price">Price</vs-th>
          <vs-th sort-key="prev_count_order" class="prev-count-order"
            >Paid in {{ prevMonth.format('MMMM') }}</vs-th
          >
          <vs-th sort-key="prev_count_fact"
            >Fact in {{ prevMonth.format('MMMM') }}</vs-th
          >
          <vs-th sort-key="prev_count_fact">Correct</vs-th>
          <vs-th sort-key="cur_count_plan"
            >Plan in {{ curMonth.format('MMMM') }}</vs-th
          >
          <vs-th sort-key="cur_count_result"
            >To pay in {{ curMonth.format('MMMM') }}</vs-th
          >
          <vs-th sort-key="value">Amount</vs-th>
          <vs-th></vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr
            :key="indextr"
            :data="tr"
            v-for="(tr, indextr) in data"
            :class="{ evening: tr.evening }"
          >
            <vs-td>
              <item-status
                :status="{ name: 'Not saved', class: 'item-danger' }"
                v-if="!tr.created || tr.edited"
              />
              <item-status
                :status="{ name: 'Saved', class: 'item-review' }"
                v-if="tr.created && !tr.edited"
              />
            </vs-td>
            <vs-td :data="tr.group.name">
              <router-link
                :to="{ name: 'Group', params: { group_id: tr.group.id } }"
                target="_blank"
              >
                <vs-avatar
                  icon="people"
                  :color="tr.group.color"
                  size="small"
                ></vs-avatar
                >{{ tr.group.name }}</router-link
              >
            </vs-td>
            <vs-td :data="tr.days">
              <div class="day" v-for="(d, i) in tr.days" :key="i">
                {{ d.name }} - {{ d.time }}
              </div>
            </vs-td>
            <vs-td :data="tr.price"> {{ tr.price }} р. </vs-td>
            <vs-td :data="tr.lesson_prev_pay">
              {{ tr.lesson_prev_pay }}
            </vs-td>
            <vs-td :data="tr.lesson_prev_fact">
              {{ tr.lesson_prev_fact }}
            </vs-td>
            <vs-td :data="tr.lesson_correct">
              {{ tr.lesson_correct }}<br />
              <div v-if="tr.correct_note" class="correct__note">
                <vs-icon icon="comment" size="12px" color="warning"></vs-icon
                >{{ tr.correct_note }}
              </div>
              <div v-if="tr.lesson_correct && tr.correct_user">
                by {{ tr.correct_user.nickname }}
              </div>
            </vs-td>
            <vs-td :data="tr.lesson_cur_plan">
              {{ tr.lesson_cur_plan }}
            </vs-td>

            <vs-td
              :data="tr.lesson_cur_pay"
              :class="{ manual: tr.lesson_manual_pay }"
            >
              {{ tr.lesson_manual_pay || tr.lesson_cur_pay }}
            </vs-td>
            <vs-td :data="tr.value" :class="{ manual: tr.lesson_manual_pay }">
              {{ tr.value }} р.
              <div v-if="tr.editor">change by {{ tr.editor.nickname }}</div>
            </vs-td>
            <vs-td :data="{}" class="orders__manage">
              <div>
                <vs-button
                  icon="edit"
                  type="flat"
                  color="dark"
                  size="small"
                  @click="selectOrder(tr)"
                  title="edit"
                >
                </vs-button>
              </div>
              <div>
                <vs-button
                  icon="healing"
                  type="flat"
                  color="dark"
                  size="small"
                  @click="correctOrderHandler($event, tr)"
                  title="correct order"
                >
                </vs-button>
              </div>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div class="order-filters">
        <h2 class="title">Filter by value:</h2>
        <div class="order-filters-values">
          <div v-for="(s, index) in sumFilters.sort()" :key="index">
            <vs-chip
              icon=""
              :color="selectedSums.includes(s) ? 'primary' : null"
              v-model="selectedSums"
            >
              <div
                class="order-filters-values__value"
                @click="filterByValueHandle(s)"
              >
                {{ s }}р.
              </div>
            </vs-chip>
          </div>
        </div>
      </div>
    </div>
    <div class="order-form" v-if="editFormActive">
      <group-order-edit-form
        :order="editOrder"
        @closeForm="closeFormHandle"
        @saveOrder="saveSingleOrderHandle"
        :prevMonth="prevMonth"
        :curMonth="curMonth"
      />
    </div>
    <div class="order-correct-form" v-if="correctFormActive">
      <group-order-correct-form
        :order="correctOrder"
        @closeForm="closeFormHandle"
        @saveOrder="saveOrderCorrectHandle"
        :prevMonth="prevMonth"
        :curMonth="curMonth"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import itemStatus from '../../common/itemStatus.vue'
import GroupOrderEditForm from './groupOrderEditForm.vue'
import GroupOrderCorrectForm from './groupOrderCorrectForm.vue'
import recalcStudents from '@/api/orders/recalcStudents'

export default {
  components: { itemStatus, GroupOrderEditForm, GroupOrderCorrectForm },
  props: ['month', 'valueFilters'],
  data() {
    return {
      editOrder: {},
      editFormActive: false,
      correctOrder: {},
      correctFormActive: false,
      selectedOrders: [],
      selectedSums: [],
      curMonth: this.$moment(),
      popupActive: false,
      loading: false,
      toSaveOrdersCount: 0,
      savedOrdersCount: 0,
    }
  },
  computed: {
    ...mapState({
      orders: state => state.orders.ordersGroups,
      currentYear: state => state.years.currentSchoolYear,
    }),
    prevMonth: function() {
      let prev = this.curMonth.clone()
      return prev.add(-1, 'month')
    },
    sumFilters: function() {
      return Array.from(new Set(this.orders.map(o => o.value))).sort()
    },
    filteredOrders: function() {
      const orders = JSON.parse(JSON.stringify(this.orders))
      if (this.selectedSums?.length) {
        return orders.filter(o => this.selectedSums.includes(o.value))
      }
      return orders
    },
  },
  methods: {
    filterByValueHandle: function(value) {
      const idx = this.selectedSums.findIndex(o => o == value)
      if (idx == -1) this.selectedSums.push(value)
      else this.selectedSums.splice(idx, 1)
    },
    selectOrder: function(data) {
      this.closeFormHandle()
      this.editOrder = data
      setTimeout(() => {
        this.editFormActive = true
      }, 50)
    },
    correctOrderHandler: function(event, data) {
      event.stopPropagation()
      this.closeFormHandle()
      this.correctOrder = data
      setTimeout(() => {
        this.correctFormActive = true
      }, 10)
    },
    closeFormHandle: function() {
      this.editFormActive = false
      this.correctFormActive = false
      this.editOrder = {}
      this.correctOrder = {}
    },
    saveSingleOrderHandle: function({ order, manual_pay, value, editor }) {
      if (this.editOrder == order) {
        const edited = true
        const index = this.orders.findIndex(o => o.group.id == order.group.id)
        order = {
          ...order,
          ...{ lesson_manual_pay: manual_pay, value, editor, edited },
        }
        this.orders.splice(index, 1, order)
        this.$vs.notify({
          title: 'Manual data added',
          text: `Please save orders`,
          color: 'warning',
          position: 'top-right',
          icon: 'save',
        })
        this.closeFormHandle()
        return
      }
      this.$vs.notify({
        title: 'Manual data not saved',
        text: `Please save data or reload page`,
        color: 'danger',
        position: 'top-right',
      })
    },
    saveOrderCorrectHandle: function({
      order,
      lesson_correct,
      lesson_cur_pay,
      value,
      correct_user,
      correct_note,
    }) {
      if (this.correctOrder.group.id == order.group.id) {
        const edited = true
        const index = this.orders.findIndex(o => o.group.id == order.group.id)
        order = {
          ...order,
          ...{
            lesson_correct,
            lesson_cur_pay,
            correct_note,
            value,
            correct_user,
            edited,
          },
        }
        this.orders.splice(index, 1, order)
        this.$vs.notify({
          title: 'Manual data added',
          text: `Please save orders`,
          color: 'warning',
          position: 'top-right',
          icon: 'save',
        })
        this.closeFormHandle()
        return
      }
      this.$vs.notify({
        title: 'Manual data not saved',
        text: `Please save data or reload page`,
        color: 'danger',
        position: 'top-right',
      })
    },
    exportTable: function() {
      // this.$emit('loading', true)
      let month = this.curMonth.month() + 1
      let year = this.curMonth.year()
      this.$store
        .dispatch('orders/exportOrdersGroupAction', { month, year })
        .then(response => {
          var url =
            'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' +
            response.body.data // Set your html table into url
          var a = document.createElement('a')
          a.href = url
          a.download = `Groups ${this.curMonth}.xlsx` //File name Here
          a.click() //Downloaded file
          this.$emit('loading', false)
          return false
        })
        .catch(() => {
          this.$emit('loading', false)
        })
    },
    saveOrders: async function() {
      this.$emit('loading', true)
      this.savedOrdersCount = 0
      let orders = this.orders
        .filter(o => o.edited || !o.id)
        .map(o => {
          return {
            ...o,
            ...{
              group_id: o.group.id,
              editor_id: o.editor ? o.editor.id : null,
              correct_user_id: o.correct_user ? o.correct_user.id : null,
            },
          }
        })

      this.toSaveOrdersCount = orders.length

      for (const order of orders) {
        await this.$store.dispatch('orders/setOrdersGroupsAction', {
          data: [order],
        })
        this.savedOrdersCount++
      }

      this.$vs.notify({
        title: 'Payment saved',
        text: `All Orders saved`,
        color: 'success',
        position: 'top-right',
        time: 3000,
      })

      this.closeProgressBar()
      this.$emit('loading', false)
    },
    recalcStudentsInGroupOrders: async function() {
      const groupOrdersSaved = this.orders.some(o => o.edited)

      if (groupOrdersSaved) {
        this.$vs.notify({
          title: 'Error calculate',
          text: `Some group orders not saved. Save group orders before calculate students`,
          color: 'danger',
          position: 'top-right',
        })
        return
      }
      this.$emit('loading', true)

      this.savedOrdersCount = 0
      this.toSaveOrdersCount = this.selectedOrders.length
      const date = {
        month: this.curMonth.month(),
        year: this.curMonth.year(),
      }
      for (const order of this.selectedOrders) {
        let data = {
          ...date,
          groups: [order.group.id],
        }
        await recalcStudents
          .call(this, {
            data,
          })
          .then(() => {
            this.$vs.notify({
              title: 'Student orders saved',
              text: `Saved student orders in ${this.curMonth}`,
              color: 'success',
              position: 'top-right',
            })
          })
          .catch(e => {
            this.$vs.notify({
              title: 'Error in student orders saving',
              text: `${e}`,
              color: 'danger',
              position: 'top-right',
            })
          })

        this.savedOrdersCount++
      }
      this.closeProgressBar()
      this.$emit('loading', false)
    },
    reloadGroupOrders: async function() {
      this.$emit('loading', true)
      let orders = this.selectedOrders.length
        ? this.selectedOrders
        : this.orders

      this.savedOrdersCount = 0
      this.toSaveOrdersCount = orders.length

      const date = {
        month: this.curMonth.month() + 1,
        year: this.curMonth.year(),
        schoolyear: this.currentYear.id,
      }
      for (const order of orders) {
        let data = {
          ...date,
          groups: [order.group.id],
        }
        await this.$store
          .dispatch('orders/getOriginalOrdersAction', data)
          .catch(e => {
            this.$vs.notify({
              title: 'Ошибка получения данных',
              text: `${e.toString()}`,
              color: 'danger',
              position: 'top-right',
            })
          })

        this.savedOrdersCount++
      }
      this.closeProgressBar()
      this.$emit('loading', false)
    },
    closeProgressBar: function() {
      setTimeout(() => {
        this.toSaveOrdersCount = 0
        this.selectedOrders = []
      }, 3000)
    },
  },
  watch: {
    month: function() {
      let month, year
      ;[month, year] = [...JSON.parse(this.month)]
      this.curMonth = this.$moment({ day: 1, month: month - 1, year: year })
    },
  },
}
</script>

<style lang="scss">
.report-table {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  .report-table__wrapper {
    .vs-con-table {
      header {
        position: sticky;
        top: 55px;
        z-index: 100;
        transition: all 0.2s;
        border-radius: 10px 10px 0 0;
      }
      .vs-table--tbody {
        z-index: 0;
      }
      .order__manage {
        & > * {
          margin-left: 10px;
        }
      }
      table {
        max-width: 99%;
        margin: 0 auto;
        border-collapse: collapse;
        tr {
          border-radius: 15px;
          &.evening {
            border: 3px solid #777;
            // background-color: #efefef !important;
          }
          .order-save-snippet {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            display: inline-block;
            margin-right: 7px;
            cursor: pointer;
            &.clickable {
              border: 1px solid #ccc;
              z-index: 100;
            }
            &.saved,
            &.clickable:hover {
              background-color: darkblue;
            }
            &.edited {
              background-color: darkorange;
            }
          }
        }
        th {
          &.prev-count-fact {
            max-width: 150px;
          }
        }
        .vs-table--thead {
          position: sticky;
          top: 100px;
          transition: all 0.2s;
          box-shadow: 0px 2px 2px 1px #ccc;
        }
        .manual {
          color: red;
        }
        .orders__manage {
          span {
            display: block;
            display: flex;
            flex-flow: row nowrap;
          }
        }
      }
    }
    .order-filters {
      position: sticky;
      box-shadow: 0 3px 10px 0 #ccc;
      bottom: 0;
      background-color: #fff;
      padding: 10px;
      border-radius: 10px 10px 0 0;
      display: flex;
      flex-flow: row nowrap;
      gap: 10px;
      align-items: center;
      .con-vs-chip {
        float: none !important;
      }
      .title {
        font-size: 1em;
        margin-bottom: 7px;
      }
      .order-filters-values {
        display: flex;
        flex-flow: row wrap;
        flex: 1;
        gap: 3px;
        .order-filters-values__value {
          cursor: pointer;
        }
      }
    }
  }
  .order-form,
  .order-correct-form {
    position: sticky;
    padding: 15px 25px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 1px 1px 3px 1px #ccc;
    transition: 0.3s all;
    z-index: 101;
    top: 11rem;
    margin-left: 15px;
    height: 230px;
  }
  .order-correct-form {
    height: 350px;
  }
}
</style>
