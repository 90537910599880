<template>
  <div>
    <vs-icon class="close" icon="close" @click="$emit('closeForm')"></vs-icon>
    <form
      @submit="saveForm($event)"
      class="edit-order-form"
      @keyup.enter="saveForm($event)"
    >
      <div class="">
        <vs-avatar icon="people" :color="order.group.color" />
        Group: {{ order.group.name }}
      </div>

      <div class="field">
        <vs-input
          :label="`Manual pay in ${curMonth.format('MMMM')}`"
          v-model="manual_pay"
          ref="input"
        ></vs-input>
      </div>

      <div class="title">To pay: {{ value || this.order.value }}</div>
      <vs-button icon="save" @click="saveForm($event)">Save</vs-button>
    </form>
  </div>
</template>

<script>
export default {
  props: ["order", "prevMonth", "curMonth"],
  data() {
    const manual_pay = this.order
      ? this.order.lesson_manual_pay || this.order.lesson_cur_pay
      : 0;
    return {
      manual_pay,
    };
  },
  computed: {
    value: function () {
      const bouns = this.order.evening ? 100 : 0;
      return this.manual_pay * this.order.price + bouns;
    },
  },
  methods: {
    saveForm: function (e) {
      e.preventDefault();
      const user = JSON.parse(localStorage.getItem("user"));
      const editor = { id: user.id, nickname: user.name };
      const { manual_pay, value } = this;
      this.$emit("saveOrder", { order: this.order, manual_pay, value, editor });
    },
    focus: function () {
      setTimeout(() => {
        this.$refs.input.$refs.vsinput.select();
      }, 500);
    },
  },
  created: function () {
    this.focus();
  },
  // updated: function () {
  //   this.focus();
  // },
};
</script>

<style lang="scss" scope>
.close {
  position: absolute;
  top: 30px;
  right: 10px;
  cursor: pointer;
}
.edit-order-form > div {
  margin: 10px 0;
}
</style>