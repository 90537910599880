<template>
  <div class="item-status status-block">
    <span class="status" :class="status.class">
      {{ status.name }}
    </span>
  </div>
</template>

<script>
export default {
  props: ["status"],
};
</script>

<style lang="scss">
.item-status {
  & > span {
    &.item-open {
      background-color: #ddd;
    }
    &.item-danger {
      background-color: rgba(var(--vs-danger), 1);
      color: #eee;
    }
    &.item-close {
      background-color: var(--font-discount-15);
      color: #eee;
    }
    &.item-review {
      background-color: rgba(var(--vs-primary), 1);
      color: #eee;
    }
  }
}
</style>